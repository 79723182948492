<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 one-click-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="one-click-header icon-hea1">
        <template v-slot:left>
          <router-link :to="{ name: 'r_one-click' }">
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div
          v-if="$route.params && $route.params.urlLinkName"
          class="component-title"
        >
          {{ $route.params.urlLinkName }}
        </div>
      </top-header-menu-wrapper>
    </template>
    <iframe
      v-if="$route.params && $route.params.urlLink"
      :src="$route.params.urlLink"
      width="100%"
      height="100%"
      frameborder="0"
    >
    </iframe>
    <template v-slot:footer> </template>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";

export default {
  name: "OneClick",
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  created() {
    if (
      this.$route.name === "r_one-click-url" &&
      (!this.$route.params || !this.$route.params.urlLink)
    ) {
      this.$router.push({
        name: "r_one-click"
      });
    }
  },
  watch: {
    $route: {
      deep: true,
      handler(val) {
        if (
          val.name === "r_one-click-url" &&
          (!val.params || !val.params.urlLink)
        ) {
          this.$router.push({
            name: "r_one-click"
          });
        }
      }
    }
  }
};
</script>
